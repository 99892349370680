<template>
  <div class="box">
    <img :src="zizhiImg" alt="" />
  </div>
</template>

<script>
import xxpng from '../assets/zizhi.png'
export default {
  // props: ["id"],
  data() {
    return {
      zizhiImg: xxpng
    }
  },
  created() {
    
  }
}
</script>

<style>
.box{
  padding: 100px 50px;
  text-align: center;
}
.box img{
  width: 100%;
}
</style>
